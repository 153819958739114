.selectSinElegir {
  width: 600px;
  
  margin-top: 15px;
}

.selectSinElegir .tituloSnoopy {
  font-family: monospace;
  font-size: 25px;
  text-align: center;
}

.gifContainer {
  max-width: 200px;
  margin: 0 auto;

}
.gifContainer img {
  width: 100%;
  height: 100%;
  object-fit:fill;
}

/* media queries */

@media screen and (max-width: 1080px) {
  .selectSinElegir {
    margin: 0 auto;
  }
}
@media screen and (max-width: 620px) {
  .selectSinElegir {
    width: 350px;
  }
  .selectSinElegir .tituloSnoopy {
    font-size: 20px;
  }
}

@media screen and (max-width: 400px) {
  .selectSinElegir {
    width: 279px;
  }
}
