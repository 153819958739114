

.mainCarousel{
    width: 98%;
    margin: 0 auto;
    margin-top: 30px;
}

.carouselTitle{
    padding: 15px;
    font-size: 25px;
    background-color: rgba(255, 85, 85, 0.5);
    border-radius: 15px;
}

.pictureImgDiv{
    border: 5px solid  rgb(93, 230, 178);
    border-radius: 15px;
    overflow: hidden;
    height: 500px;
    
}
.pictureImgDiv img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    
}

@media screen and (max-width: 820px) {
    .mainCarousel{
        width: 90%;
        margin: 0 auto;
        margin-top: 10px;
    }
    .pictureImgDiv img{
        object-fit:cover;
    }
}

@media screen and (max-width: 420px) {
    .mainCarousel{
        width: 98%;
        margin: 0 auto;
        margin-top: 5px;
    }
    .pictureImgDiv img{
        object-fit:cover;
    }
}