.caja { 
    font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
    color: black; 
    font-size: 18px; 
    font-weight: 400; 
    text-align: center; 
   /*  background: #889ccf;  */
    margin: 0 0 25px; 
    overflow: hidden; 
    padding: 20px; 
    border-radius: 35px 0px 35px 0px; 
    -moz-border-radius: 35px 0px 35px 0px; 
    -webkit-border-radius: 35px 0px 35px 0px; 
    border: 3px solid rgb(93, 230, 178);
}

@media only screen and (max-width: 500px) {
	.caja  { 
        font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
        color: black; 
       /*  font-size: 15px;  */
        font-weight: 400; 
        text-align: center; 
      /*   background: #889ccf;  */
        margin: 0 0 10px; 
        overflow: hidden; 
        padding: 5px; 
        border-radius: 35px 0px 35px 0px; 
        -moz-border-radius: 35px 0px 35px 0px; 
        -webkit-border-radius: 35px 0px 35px 0px; 
        border: 2px solid rgb(93, 230, 178);

	}
    .titles{
        font-size: 15px; 
    }
}

@media only screen and (max-width: 400px) {
	.caja  { 
        font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
        color: black; 
        /* font-size: 9px;  */
        font-weight: 400; 
        text-align: center; 
        /* background: #889ccf;  */
        margin: 0 0 10px; 
        overflow: hidden; 
        padding: 5px; 
        border-radius: 35px 0px 35px 0px; 
        -moz-border-radius: 35px 0px 35px 0px; 
        -webkit-border-radius: 35px 0px 35px 0px; 
      border: 2px solid rgb(93, 230, 178);
	}
.titles{
    font-size: 11px; 
}
}