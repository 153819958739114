.mainUsuarioContainer {
    display: flex;
    min-height: 70vh;
    
  }
  
.menuYdatosPaciente{
  
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


  .menuUsuario {
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: rgb(93, 230, 178);
    border: 2px solid rgb(76, 201, 82);
    border-radius: 15px;
    min-width: 200px;
    margin: 1rem;
    padding-bottom: 30px;
    
  }
  .usuarioTitle {
    text-align: center;
  
  }
  .options {
    border-radius: 15px;
    padding: 3px 15px;
    color: #0d6efd;
    font-weight: 600;
    margin-left: 15px;
    margin-right: 10px;
  }
  
  .options:hover {
    cursor: pointer;
    background-color: rgb(146, 146, 146);
    color: blanchedalmond;
  }
  
  .mainRenderUsuario {
    width: 100%;
    margin-left: 25px;
    
  }
  
  .misDatosUsuarioPaciente{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(167, 167, 167);
    border-radius: 10px;
    margin: 1rem;
    padding: 10px;
    width:300px
  }

  /* media queries */
  
  @media screen and (max-width: 1080px) {
    .mainUsuarioContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .mainRenderUsuario {
        margin-left: 0;
        margin-top: 25px;
       }
       .menuYdatosPaciente{
        
        display: flex;
        flex-direction:row;
        
      }
  
  }
  
  @media screen and (max-width: 620px) {
    .menuYdatosPaciente{
      margin-top: 25px;
      flex-direction: column;
      align-items: center;
    }
        .misDatosUsuarioPaciente{
          margin: 0;
          padding: 10px;
          width:100%
        }
  }