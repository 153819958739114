.mainFooterContainer {
  display: flex;
  justify-content: space-evenly;    
  min-height: 300px;
  background-color: rgb(93, 230, 178);
  width: 100%;
  box-shadow: 1px 0px 20px 0px #7daf77;

  
  /* text-align: center; */
}

.infoFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0px 0px 20px;

}
.imagenYlogoFooter {
  margin-bottom: 20px;
}
.imagenYlogoFooter span {
  margin-left: 5px;
  font-size: 25px;
  font-weight: 700;
}

.footerParagraph {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 18px;
  margin-left: 50px;
}
.copyRight {
  font-style: italic;
  font-size: 15px;
  margin-top: 40px;
  margin-left: 50px;
}

.novedades {
  width: 30%;
  padding: 20px 0px 0px 20px;
}
.novedades span {
  margin-left: 5px;
  font-size: 25px;
  font-weight: 700;
}
.linksFooter{
    padding-left: 5px;
    margin-top: 10px;
}
.linksFooter a{
    font-size: 18px;
    font-weight: 700;
}
.linksFooter a:hover{
    color: rgb(255, 255, 255);
    text-shadow: -1px -1px 10px #3c0404 !important;
}

.footerIcons{
    display: flex;
    align-items: center;
    margin-left: 42px;
    
}
.footerIcons a:nth-child(1){
    color:white;
    background-color: rgb(255, 0, 149);
    padding: 3px 1px;
    border-radius: 11px;
}
.footerIcons a:nth-child(2){
    margin-left: 15px;
    color: white;
    background-color: rgb(3, 3, 199);
    padding: 4px 2px;
    border-radius: 11px;
    
}
.footerIcons a:hover{
    color:rgb(29, 17, 206);
    background-color: unset;
}

@media screen and (max-width: 920px) {
    .novedades{
        display: none;
    }
    .infoFooter {
        padding: 0;
      }
}

@media screen and (max-width: 620px) {
    .imagenYlogoFooter img{
        display: none;
      }
      .infoFooter {
        text-align: center;
      }
}