.mainTitleTextoHome2{
	text-align:left;
	
	color: yellowgreen;
	font-size: 1.5rem;
	width: 95%;
	margin: 0 auto;
}


.sliderDescription { 
	width: 95%;
	text-align: justify;
	font-size: 1.2rem;
	margin: 0 auto;
}

@media only screen and (max-width: 400px) {
	.sliderDescription { 
		font-size: 1.2rem;
		

	}
	.mainTitleTextoHome2{
		text-align: center;
	}
}