.mainContainerPassRecover{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right, #9900ff, #cc80ff);

}
.heading{
    font-family: monospace;
  }
  
.formRecovery{
    display: flex;
    flex-direction: column;
    width: 480px;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(255, 250, 250, 0.2);
    padding: 30px;
    border-radius: 10px;
    margin:1rem;
}


.selectLogin select{
    margin-top: 0.5rem;
    border: 3px solid #9900ff;;
    padding: 0.5em;
    border-radius: 15px;
    font-weight: 700;
    font-size: 1rem;
    color: #313131;
    
  }
  .selectLogin select option{ 
    font-weight: 500;
    font-size: 1rem;
    color: #313131;
    border:none;
  }
  .labelSelect{
    font-weight: 700;
    font-size: 1rem;
    color: #313131;
  }
  .submit button {
    outline: none;
    border: none;
    background-color: #9900ff;
    color: #fff;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 16px;
    width: 100%;
    transition: 100ms;
    cursor: pointer;
    margin-top: 15px;
  }
  .submit button:hover {
    background-color: #aa2aff;
  }


   
  .submit button:disabled {
    background-color: gray !important;
  }
  @media screen and (max-width: 620px) {
    
    .formRecovery {
      width: 98%;
      padding: 10px;
      
      
    }
  }