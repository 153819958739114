.continer {
  background-size: cover;
  font: 14px/20px "Lato", Arial, sans-serif;
  color: #9e9e9e;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 10px;
  background: white;
  width: 300px;
  display: inline-block;
  /* margin: auto; */
  border-radius: 15px;
  position: relative;
  text-align: center;
  box-shadow: -1px 15px 30px -12px #35bb23;
  z-index: 9999;
  border: 2px solid #35bb23;
}

.fecha {
  text-transform: uppercase;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 3px;
  color: #e75907f1;
}
.title {
  font-size: 20px;
  color: black;
  font-weight: 900;
  margin-bottom: 10px;
}
.evaluacion {
  padding: 20px;
  margin-bottom: 10px;
  font: 17px "Lato", Arial, sans-serif;
  color: black;
  text-align: justify;
}

.buttonContact {
  background: linear-gradient(180deg, #35bb23 26.71%, #63e029 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(129, 189, 32, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  margin-top: 25px;
  margin-left: 5rem;
  margin-bottom: 10px;
}
.button-contact:hover {
  background: white;
  cursor: pointer;
  color: var(--orange);
}

.paciente {
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 15px;
  font-weight: 500;
}

.profesionalPorIdError {
  width: 100%;
  text-align: center;
  font-size: 25px;
  margin-top: 2rem;
}

.profesionalPorIdError span {
  color: red;
  font-weight: 700;
}
