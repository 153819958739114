.ImageTopFooter{
    background-image: url("../../assets/Familiar/familia.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
   /*  position: absolute;
    bottom: 192px; */
}
.ImageTopFooter img{
    width: 100%;
    height:100%;
}