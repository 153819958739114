.sliderDescription { 
	bottom: 5rem;
	width: 100%;
	text-align: center;
	font-size: 1.25rem;
	padding: 0px 10px;
}
h5{
	background-color: rgb(90, 84, 84);
	color: white;
	margin: 10px 10px 10px 10px;

}
@media (min-width: 992px) and (max-width: 1380px) {
	.sliderDescription{
		font-size: 1.15rem;
	}
}
@media screen and (max-width: 992px){
	.sliderDescription{
		font-size: 1.1rem;
	}
}
@media screen and (max-width: 600px){
	
	.sliderDescription{
		font-size: 0.95rem;
	}

}
