.container {
    width: 100%;
    background: linear-gradient(to right, #9900ff, #cc80ff);
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .innerBox {
    width: 480px;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    
  }
  .heading{
    font-family: monospace;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  

  .footer button {
    outline: none;
    border: none;
    background-color: #9900ff;
    color: #fff;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 16px;
    width: 100%;
    transition: 100ms;
    cursor: pointer;
  }
  
  .footer button:disabled {
    background-color: gray !important;
  }
  
  .footer button:hover {
    background-color: #aa2aff;
  }
  
  .footer p {
    font-weight: 700;
    color: #000;
  }
  
  .footer p span a {
    font-weight: bold;
    color: #9900ff;
    letter-spacing: 1px;
    font-size: 1rem;
    text-decoration: none;
  }



  .selectLogin select{
    margin-top: 0.5rem;
    border: 3px solid #9900ff;;
    padding: 0.5em;
    border-radius: 15px;
    font-weight: 700;
    font-size: 1rem;
    color: #313131;
    
  }
  .selectLogin select option{ 
    font-weight: 500;
    font-size: 1rem;
    color: #313131;
    border:none;
  }
  .labelSelect{
    font-weight: 700;
    font-size: 1rem;
    color: #313131;
  }

  @media screen and (max-width: 620px) {
    
    .innerBox {
      width: 98%;
      padding: 10px;
      
      
    }
  }