.sliderDescription { 
	bottom: 5rem;
	width: 100%;
	text-align: center;
	font-size: 1.25rem;
}
h5{
	background-color: rgb(90, 84, 84);
	color: white;
	margin: 10px 10px 10px 10px;

}

@media only screen and (max-width: 400px) {
	.sliderDescription { 
		bottom: 5rem;
		width: 100%;
		text-align: center;
		font-size: 0.95rem;
	}
	h5 {
		font-size: 0.98rem;
	}
}