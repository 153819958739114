a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    a, a:hover {
      text-decoration: none !important; }
  
  .text-black {
    color: #000; }
  
  .content {
    padding: 2rem 0; }
  
  .heading {
    font-size: 2.5rem;
    font-weight: 900; }
    
#nombre{
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    background: none;
}
#email{
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    background: none;
}
#phone{
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    background: none;
}
#company{
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    background: none;
}
#message{
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    background: none;
}
  .formulario {
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    background: none; }
    .formulario:active, .formulario:focus {
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-color: #000; }
  
  .col-form-label {
    color: #000;
    font-size: 13px; }
  
  .btn, .formulario, .custom-select {
    height: 45px; }
  
  .custom-select:active, .custom-select:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000; }
  
 
    .btn:hover {
      color: #fff; }
    .btn:active, .btn:focus {
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none; } 
  
  .contact-wrap {
    -webkit-box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2); }
    .contact-wrap .col-form-label {
      font-size: 14px;
      color: #b3b3b3;
      margin: 0 0 10px 0;
      display: inline-block;
      padding: 0; }
    .contact-wrap .form, .contact-wrap .contact-info {
      padding: 40px; }
    .contact-wrap .contact-info {
      color: rgba(255, 255, 255, 0.5); }
      .contact-wrap .contact-info ul li {
        margin-bottom: 15px;
        color: rgba(255, 255, 255, 0.5); }
        .contact-wrap .contact-info ul li .wrap-icon {
          font-size: 20px;
          color: #fff;
          margin-top: 5px; }
    .contact-wrap .form {
      background: #fff; }
      .contact-wrap .form h3 {
        color: #35477d;
        font-size: 20px;
        margin-bottom: 30px; }
    .contact-wrap .contact-info {
      background: #35477d; }
      .contact-wrap .contact-info h3 {
        color: #fff;
        font-size: 20px;
        margin-bottom: 30px; }
  
  label.error {
    font-size: 12px;
    color: red; }
  
  #message {
    resize: vertical; }
  
  #form-message-warning, #form-message-success {
    display: none; }
  
  #form-message-warning {
    color: #B90B0B; }
  
  #form-message-success {
    color: #55A44E;
    font-size: 18px;
    font-weight: bold; }
  
  .submitting {
    float: left;
    width: 100%;
    padding: 10px 0;
    display: none;
    font-weight: bold;
    font-size: 12px;
    color: #000; }
    