.navBarContainer {
  box-shadow: -4px -16px 20px 18px;
  background: rgb(93, 230, 178);
}
.navbar-nav {
  margin-left: 5rem;
}

.listNavBar {
  display: flex;
  justify-content: space-around;
}
.navBarLinks {
  margin-left: 5rem;
  font-size: 18px;
  color: white;
  padding-top: 3px;
}
.navBarLinks svg {
  width: 1.5em;
  height: 1.5em;
  color: white;
}
.listNavBar:hover {
  color: rgb(153, 15, 15) !important;
}
.navBarLinks:hover {
  font-size: large;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.LogoNavBar {
  width: 120px;
}

.buttonContact {
  background: linear-gradient(180deg, #35bb23 26.71%, #63e029 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(129, 189, 32, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  margin-left: 5rem;
}
.button-contact:hover {
  background: white;
  cursor: pointer;
  color: var(--orange);
}

@media screen and (max-width: 1200px) {
  .LogoNavBar {
    width: 150px;
  }
  .navbar-nav {
    margin-left: none;
  }
  .navBarLinks {
    margin-left: 2rem;
  }
  .buttonContact {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 740px) {
  .listNavBar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttonContact {
    margin-left: -2rem;
  }
  .reservarCitaBoton{
    margin-top: 1rem; 
  }
}
@media screen and (max-width: 414px) {
  .navbar-nav {
    margin-left: none !important;
  }
  .LogoNavBar {
    width: 85px;
  }
  .reservarCitaBoton{
    margin-top: 1rem; 
  }
  
  
}
