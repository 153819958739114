.container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
}
.cardContainer{
    width: 400px;
    height: 270px;
    border: 2px solid rgb(0, 0, 0);
    padding-top: 50px;
    padding-left: 50px;
    margin:15px;
    
}
.SvgContainer svg{
    height: 40px;
    width: 40px;
}
.titleCard{
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 15px;
}

.btnVerMas{
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    background-color: unset;
}
.btnVerMas:hover{
    margin-left: 5px;
}
.cardContainer p{
    font-style: italic;
}

.cardContainer:nth-child(1),.cardContainer:nth-child(3){
    background-color: grey;
}