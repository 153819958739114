
.mainResetContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right, #9900ff, #cc80ff);
}
.formReset{
    display: flex;
    flex-direction: column;
    width: 480px;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(255, 250, 250, 0.2);
    padding: 30px;
    border-radius: 10px;
    margin:1rem;
}


.passwordEye{
    cursor: pointer;
    width: 23px;
    height: 23px;
    margin-left: 95%;
}


.botonResetPassword button{
    outline: none;
    border: none;
    background-color: #9900ff;
    color: #fff;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 16px;
    width: 100%;
    transition: 100ms;
    cursor: pointer;
    margin-top: 15px;
}

.botonResetPassword button:hover {
    background-color: #aa2aff;
  }


   
  .botonResetPassword button:disabled {
    background-color: gray !important;
  }
  @media screen and (max-width: 620px) {
    
    .formReset {
      width: 98%;
      padding: 10px;
      
    }
  }