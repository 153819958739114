
.tituloProfesionalesH1{
    font-family: monospace;
    text-align: center;
    text-shadow:1px 0px 12px #686363;
    margin: 25px 0px;
    
}


/* container general de cartas de profesionales */
.galeriaDeProfesionales{
    display: flex;
    flex-wrap: wrap;
}

/* container de la card individual */
.mainCardContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(214, 214, 214);
    box-shadow: 1px 0px 5px 1px #686363;
    border-radius: 5px;
    width: 350px;
    min-height: 350px;
    margin:5px;
    
}
/* container solo de la imagen */
.imagenProfesional{
    width: 150px;
    height: 150px;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 15px;
    box-sizing: border-box;
    border: solid #d6d6d6 1px;
    
}
.imagenProfesional img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}
.nombreProfesional{
    font-family: monospace;
    font-size: 20px;
    font-weight: 600;
    margin-top: 3px;

}
.nombreProfesional:hover{
    margin-left: 3px;
}
.commonProLabel,.especialidad{
    font-weight: 500;
    font-style: italic;
    margin: 3px 0px;
}
.especialidad{
    text-align: center;
    color: brown;
    font-size: 18px;
}


/* Error message */
.errorContainer{
    width: 98%;
    text-align: center;
    
}
.errorContainer span{
    font-style: italic;
    color: rgb(204, 0, 0);
}


/* media queries */

@media screen and (max-width: 1080px) {
    .galeriaDeProfesionales{
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
    }
    .mainCardContainer:last-child{
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 400px) {
    .mainCardContainer{
        text-align: center;
        width: 279px;
        height: auto;
        margin: 5px 1px;
        padding-bottom: 15px;
        
        
    }
    .mainCardContainer:last-child{
        margin-bottom: 25px;
    }
    .imagenProfesional{
        width: 100px;
        height: 100px;
       
        
    }
}
