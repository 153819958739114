.nombreProfesionalReserva{
    font-size: 25px;
    font-family: monospace;
}
.nombreProfesionalReserva span{
    font-style: italic;
}
.botonesReservarTurno, .botonesDeHorarios{
    padding: 5px;
    font-family: monospace;
    border-radius: 15px;
    background-color: darkcyan;
    color: white;
    
}

.botonesReservarTurno:hover{
    color: black;
    margin-left: 5px;
    transition: 500ms;
}

.botonesDeHorarios{
    margin-right: 5px;
}
.botonesDeHorarios:hover{
    color: black;
    background-color: whitesmoke;
    transition: 500ms;
}



.elegiTurno{
    font-family:monospace;
    margin-top:10px;
    margin-bottom:10px;
    font-weight:600;
    font-style: italic;
}


@media screen and (max-width: 1080px) {
    .mainReservarTurnoContainer,.mainSelectFechaContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .botonCambiarProfesional{
        margin-top: 5px;
        margin-bottom: 5px;
    }
    
}