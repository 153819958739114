.thEdit {
  background-color: rgb(93, 230, 178);
  border: 1px rgb(0, 0, 0) solid;
  padding-left: 2px;
  padding-right: 2px;
  color: rgb(0, 54, 116);
  text-align: center;
}

.tdEdit {
  border: 1px black solid;
  padding: 7px;
  text-align: center;
}
/* .tdEditConditional { 
  border: 1px black solid !important;
  padding-left: 5px; 
  padding-right: 5px;
  background-color: #d32f2f62!important;
} */


tr:nth-child(odd) {
  background-color: white;
}
tr:nth-child(even) {
  background-color: rgb(235, 225, 225);
}
tr:nth-child(odd) {
  background-color: white
}

.editBtn:hover {
  cursor: pointer;
  background-color: darkgray;
  border-radius: 25px;
}

.noRegistersFound {
  margin-top: 2rem;
  margin-left: 10rem;
  margin-right: 10rem;
  font-size: 25px;
  padding: 1rem;
  border-radius: 15px;
  border-color: #4e5856;
  background-color: #4e5856b0;
  color: #d32f2f;
}

.filterInput {
  border: 2px solid #d32f2f;
  border-radius: 10px;
}

.tableTitle {
  font-family: monospace;
  text-shadow: 3px 2px 5px grey;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.table-wrapper {
  width: 100%;
  height: 565px;
  overflow: auto;
  background-color: #4e5856b0;
  border: 2px rgb(87, 83, 83) solid;

}

.table-wrapper table {
  border-collapse: separate;
  border-spacing: 0;
    
}

.table-wrapper table thead {
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
}



@media screen and (max-width: 1027px) {
 
  .editBtn {
    width: 20%;
    text-align: center;
    border: 1px solid rgb(94, 92, 92);
    border-radius: 12px;
  }

  .pivoted:nth-child(even) {
    background-color: rgb(235, 225, 225);
  }

  .pivoted:nth-child(odd) {
    background-color: rgb(255, 255, 255);
  }

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;

  }

  .responsiveTable tbody tr {
    border: 1px solid #000;
    padding: .25em;
  }

  .responsiveTable td.pivoted {
    
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .responsiveTable td .tdBefore {
    position: absolute;
    display: block;
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }

  .noRegistersFound {  
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .table-wrapper {
   width: 100%;
   /* min-height: 100vh; */
   height: 100%;
   overflow:hidden;
  
  }
}

@media screen and (max-width: 720px) {
  .noRegistersFound {  
    margin-left: 0rem;
    margin-right: 0rem;
    font-size: 18px;
  }


}