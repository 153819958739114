
*{
  padding: 0;
  margin: 0;
}
.mainServiceContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
}
.flex {
  display: grid;
  grid-template-columns: repeat(3,351px);
  grid-auto-rows: auto;
  gap: 10px;
  margin-bottom: 20px;
}



.tituloPrincipalServicio{
  color: yellowgreen;
  width: 90%;
  margin-left: 50px;
  margin-top:2rem;
}


.selectSimpleContainer{
  width: 30%;
  margin-top: 20px;
  margin-bottom: 20px;
  
}


.serviciosParrafo {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  width: 90%;
  font-size: 18px;
  margin-left: 50px;
}

@media screen and (max-width: 1110px) {
  .flex{
    grid-template-columns: repeat(2,351px);
  }
  .selectSimpleContainer{
    width: 60%;
    margin-top: 15px;
    margin-bottom: 15px;
    
  }
  
.tituloPrincipalServicio{
  font-size: 35px;
}
}

@media screen and (max-width: 740px) {
  .flex{
    grid-template-columns: repeat(1,351px);
  }
}

@media screen and (max-width: 620px) {
  .selectSimpleContainer{
    width: 80%;
   
    
  }
}
@media screen and (max-width: 400px) {
  .selectSimpleContainer{
    width: 98%;
  }
  .flex{
   display: flex;
   flex-direction: column;
   align-items: center;
  }
}

