.container {
    
    /* min-height: 100vh; */
    width: 100%;
    background: linear-gradient(to right, #9900ff, #cc80ff);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .innerBox {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    padding: 30px;
    border-radius: 10px;
    min-width: 480px;
    gap: 30px;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .footer .error {
    font-weight: bold;
    font-size: 0.875rem;
    color: #ff3300;
    text-align: center;
  }
  
  .footer button {
    outline: none;
    border: none;
    background-color: #9900ff;
    color: #fff;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 16px;
    width: 100%;
    transition: 100ms;
    cursor: pointer;
  }
  
  .footer button:disabled {
    background-color: gray !important;
  }
  
  .footer button:hover {
    background-color: #aa2aff;
  }
  
  .footer p {
    font-weight: 700;
    color: #000;
  }
  
  .footer p span a {
    font-weight: bold;
    color: #9900ff;
    letter-spacing: 1px;
    font-size: 1rem;
    text-decoration: none;
  }


  @media screen and (max-width: 620px) {
    .innerBox {
     
      min-width: 380px;
      
    }
    
  }
  @media screen and (max-width: 414px) {
    .innerBox {
     
      min-width: 290px;
      
    }
  }